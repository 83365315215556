<div class="search-filters">
	<div class="search-filters--form" >
		<p-datePicker [touchUI]="isTouchUI"  placeholder="Период поиска" name="event-date-time" [(ngModel)]="selectDate$" [showIcon]="true" [iconDisplay]="'input'" dateFormat="dd.mm.yy" selectionMode="range">
			<ng-template pTemplate="footer">
				<p-button class="p-datepicker-prev hidden">Previous</p-button>
				<p-button class="p-datepicker-next hidden">Next</p-button>
			</ng-template>
		</p-datePicker>
		<p-iconField iconPosition="right">
			<p-inputIcon styleClass="pi pi-map"></p-inputIcon>
			<p-autocomplete
				[(ngModel)]="defaultAdress"
				(ngModelChange)="onAddressChange($event)"
				(completeMethod)="searchByAddress($event)"
				[suggestions]="selectName"
				(onSelect)="selectAdress($event)"
				[showEmptyMessage]="true"
				[showClear]="true"
				[panelStyle]="{ 'max-width': '100%' }"
				[inputStyle]="{ 'border-radius':'8px' }"
				[fluid]="true"
				placeholder="Адрес"
				pTooltip="{{defaultAdress}}"
				tooltipPosition="bottom"
				>
			</p-autocomplete>
			<small (click)="visibleMap()" >Показать на карте</small>
		</p-iconField>
		<div class="tag-text tag-text--span">
			@if (defaultAdress) { {{ defaultAdress }} } @else { Укажите адрес или место на карте }
			<div class="tag-text--arrow"></div>
		</div>
		<div class="search-filters--btn">
			<p-button class="search-filters--btn__settings-icon" icon="pi pi-sliders-h" severity="secondary" (click)="showDialogSettings()" pTooltip="Радиус {{ mapHeaderService.radiusSubjectKm | async}} км" tooltipPosition="bottom"></p-button>
			<p-button class="search-filters--btn__settings-btn" severity="secondary" (click)="showDialogSettings()">Дополнительно</p-button>
			<p class="tag-text tag-text-center">
				<span class="tag-text--span tag-text-first-el">Радиус {{ mapHeaderService.radiusSubjectKm | async}} км</span>
				@if (typesSearchTags.length > 0 && typesSearchTags.length <= 3) {
					@for (item of typesSearchTags; track $index) {
						<span class="tag-text--span tag-space">{{ item | transformTags: typeImageService.types }}</span>
					}
				} @else if (typesSearchTags.length > 3) {
					<span class="tag-text--span tag-space">Все события</span>
				}
				</p>
			<p-button type="submit" [loading]="wsService.isLoading | async" (click)="submitSearchEvent()" [disabled]="!safeSelectDate.length || !selectName.length">Найти</p-button>
		</div>
	</div>
<p-dialog header="Доп настройки поиска"
			[modal]="true"
			[blockScroll]="false"
			[(visible)]="openSettings"
			[style]="{ width: '35rem'}"
			>
	<div class="edit-card-search-box">
	<div>
		<h3 class="edit-card-checkbox">теги</h3>
		<div class="card edit-card-configure-block">
			<p-multiselect
				appendTo="body"
				[options]="typeImageService.types"
				name="event-type"
				optionLabel="name"
				[maxSelectedLabels]="100"
				(onChange)="selectTags($event)"
				[placeholder]="'Выберите тег'"
				[maxSelectedLabels]="3"
				[selectedItemsLabel]="typesSearchNames.length > 3 ? typesSearchNames.slice(0, 3).join(', ') + '...' : typesSearchNames.join(', ')"
			/>
		</div>
	</div>
	<div>
	<h3 class="edit-card-checkbox">радиус</h3>
	<div class="card flex justify-center">
		<div class="edit-card-input-block">
			<div class="flex items-center" style="padding: 5px 0;">
				<p-radiobutton name="5" value="5000" [(ngModel)]="radius" inputId="radius1" (ngModelChange)="mapHeaderService.setRadius(radius)" />
				<label for="radius1" class="ml-2" style="margin-left: 10px;">5 км</label>
			</div>

			<div class="flex items-center" style="padding: 5px 0;">
				<p-radiobutton name="10" value="10000" [(ngModel)]="radius" inputId="radius2" (ngModelChange)="mapHeaderService.setRadius(radius)" />
				<label for="radius2" class="ml-2" style="margin-left: 10px;">10 км</label>
			</div>

			<div class="flex items-center" style="padding: 5px 0;">
				<p-radiobutton name="25" value="25000" [(ngModel)]="radius" inputId="radius3" (ngModelChange)="mapHeaderService.setRadius(radius)" />
				<label for="radius3" class="ml-2" style="margin-left: 10px;">25 км</label>
			</div>

			<div class="flex items-center" style="padding: 5px 0;">
				<p-radiobutton name="50" value="50000" [(ngModel)]="radius" inputId="radius4" (ngModelChange)="mapHeaderService.setRadius(radius)" />
				<label for="radius4" class="ml-2" style="margin-left: 10px;">50 км</label>
			</div>
		</div>
	</div>
	</div>
	</div>
	<p-button (onClick)="openSettings =!openSettings">Применить</p-button>
</p-dialog>
</div>
