import { Injectable } from "@angular/core";
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { BehaviorSubject, } from "rxjs";

@Injectable({
	providedIn: 'root'
})

export class PreloaderService {
	public isLoading = new BehaviorSubject(false);

	constructor(private router: Router) {
		this.router.events.subscribe({
			next: (event) => {
				if (event instanceof NavigationStart) {
					this.navigationStart();
					return;
				}

				if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
					this.navigationEnd();
				}
			}
		});
	}

	public setIsLoading(isLoad: boolean): void {
		this.isLoading.next(isLoad);
	}

	private navigationStart(): void {
		if (this.isLoading.value) return;
		this.isLoading.next(true);
	}

	private navigationEnd(): void {
		if (!this.isLoading.value) return;
		this.isLoading.next(false);
	}
}
