<div class="profile-card">
	<div class="profile-card__left">
	</div>
	<img class="profile-card__avatar" src="../../../../assets/images/profile.png">
	<div class="profile-card__center">
		<h2 class="profile-card__name">
			@if(!user.premium_finished_at){
			<p>{{ user.name }}</p>
			} @else {
				<div >
					<div class="profile_premium">
						{{ user.name }}
						@if(!paymentService.isDateExpired(user.premium_finished_at)){
							<span class="profile_title_premium">Premium активен до {{user.premium_finished_at * 1000 | date:'d MMMM yyyy'}}</span>
						}
					</div>
				</div>
			}
		</h2>
		<div class="profile-card__smileys">
			<span *ngFor="let item of user.features">{{ item }}</span>
		</div>
		<p class="profile-card__description_header"> Обо мне: </p>
		<p class="profile-card__description">{{ user.description }}</p>
		@if (user.link !== "") {
			<a href="{{ user.link }}" class="profile-card__social">Мои социальные сети</a>
		}
	</div>
	<div class="profile-card__right">
		@if (currentUserId === user.id) {
			<div class="card flex justify-center">
				<p-menu #menu [model]="items" [popup]="true" />
				<p-button (click)="menu.toggle($event)" icon="pi pi-ellipsis-v"/>
			</div>
		} @else {
			@if (connect === null) {
				<p-button icon="pi pi-user-plus" [rounded]="true" [outlined]="true" severity="secondary" (click)="suggestFriendship()" [disabled]="waitRequest"></p-button>
			} @else if (connect.approve) {
				<p-button icon="pi pi-user-minus" [rounded]="true" [outlined]="true" severity="danger" (click)="approveFriendship(false)" [disabled]="waitRequest"></p-button>
			} @else if (!connect.sender) {
				<p-button icon="pi pi-spinner-dotted" [rounded]="true" [outlined]="true" severity="secondary" [disabled]="true"></p-button>
			} @else {
				<p-button icon="pi pi-user-plus" [rounded]="true" [outlined]="true" severity="success" (click)="approveFriendship(true)" [disabled]="waitRequest"></p-button>
			}
		}
	</div>
	<div class="edit-profile-mobile">
		@if (currentUserId === user.id) {
				<p-button severity="secondary" (click)="goToBot()"> Редактировать </p-button>
				<p-button severity="secondary" (click)="exit()"> Выйти </p-button>
		} @else {
			@if (connect === null) {
				<p-button  severity="secondary" [disabled]="waitRequest" (click)="suggestFriendship()"> Добавить в друзья </p-button>
			} @else if (connect.approve) {
				<p-button  severity="secondary" [disabled]="waitRequest" (click)="approveFriendship(false)"> Удалить в друзья </p-button>
			} @else if (!connect.sender) {
				<p-button  severity="secondary" [disabled]="true"> В ожидании ответа </p-button>
			} @else {
				<p-button  severity="secondary" [disabled]="waitRequest" (click)="approveFriendship(true)"> Добавить в друзья </p-button>
			}
		}
	</div>
</div>
