import { Events } from 'src/app/core/interfaces/events';
import {
	signal,
	ChangeDetectionStrategy,
	Component,
	effect,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
	Injector,
	input,
	model, HostListener,
	ChangeDetectorRef,
	output,
} from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { WebsocketService } from 'src/app/core/services/websocket/websocket.service';
import moment from 'moment';
import { MultiSelectChangeEvent } from 'primeng/multiselect';
import { CoordinateService } from '../../../core/services/coordinates/coordinate.service';
import { EventsMainSubClass } from '../../subclass/events-main.subclass';
import { GeoCodingService } from '../../../core/services/coordinates/geocoding.service';
import { MapHeaderService } from '../map/map-header/map-header.service';
import { TypeImageService } from '../../../core/services/type-image.service';

interface AutoCompleteCompleteEvent {
	originalEvent: Event;
	query: string;
}

@Component({
	selector: 'app-event-search-filters',
	templateUrl: './event-search-filters.html',
	styleUrls: ['./event-search-filters.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventSearchFiltersComponent
	extends EventsMainSubClass
	implements OnDestroy, OnInit {
	isTouchUI: boolean = window.innerWidth < 720;

	openSettings: boolean = false;

	@HostListener('window:resize', ['$event'])
	public onResize(): void {
		this.checkScreenSize();
	}

	radius!:number;

	public selectName: string[] = [""];

	public date!: string;

	public time!: string;

	public typesSearchTags: number[] = [];

	public typesSearchNames = [];

	@Output() eventsArr = new EventEmitter<Events.ItemParsed>();

	@Output() clearNumberPage = new EventEmitter<void>();

	public eventsMap = output<Events.EventResponseData<Events.ItemPartsWs[]>>();

	public submitNewSearch = output();

	public isAuth = input();

	public selectedStartDate$ = signal<moment.Moment | null>(moment());

	public selectedEndDate$ = signal<moment.Moment | null>(null);

	public selectDate$ = signal<string[]>([]);

	get safeSelectDate(): string[] {
		return this.selectDate$() || [];
	}

	public isOpenMap = model<boolean>();

	public time$ = signal(60 * 24);

	public address$ = new Subject<string>();

	public defaultAdress: string = '';

	constructor(
		protected override wsService: WebsocketService,
		protected override coordinateService: CoordinateService,
		private injector: Injector,
		public geoService: GeoCodingService,
		private cdr: ChangeDetectorRef,
		public mapHeaderService: MapHeaderService,
		public typeImageService: TypeImageService
	) {
		super(wsService, coordinateService,mapHeaderService);

		this.address$.pipe(debounceTime(1000), distinctUntilChanged(), takeUntil(this.destroy$)).subscribe({
			next: (value) => {
				this.coordinateService.searchCoordinateByAddress(value);
				this.cdr.detectChanges();
			}
		});

		this.setTimeMinutes();



		this.wsService
			.on<Events.EventResponseData<Events.ItemPartsWs[] | Events.ItemWsDefault>>()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (val) => {
					if (val.labels && val.labels.container_type === 'event_grouped') {
						this.eventsMap.emit(val as Events.EventResponseData<Events.ItemPartsWs[]>);
						return;
					}

					if (val.events === undefined) {
						this.request.set(false);
						return;
					}

					if (val.labels.container_type === 'event') {
						this.setEvent(val as unknown as Events.EventResponseData);
					}

				},
			});

		effect(() => {
			const date = this.selectDate$();
			if (date) {
				this.selectedStartDate$.set(moment(date[0]));

				if (date.length > 1) {
					this.selectedEndDate$.set(moment(date[0], date[1]));
				}
			}

			this.clearOldDate();

			if (date && date[1]) {
				this.selectedEndDate$.set(moment(date[1]));
			}
		}, { allowSignalWrites: true });
	}

	ngOnInit(): void {
		this.geoService.selectCoordinate.subscribe({
			next: v => {
				this.selectName = v.map(item => item.display_name);
				this.cdr.detectChanges();
			}
		});

		this.geoService.currentAdress.subscribe({
			next: v => {
				this.defaultAdress = v;
				this.cdr.detectChanges();

			}
		});

		this.clearPage$.pipe(takeUntil(this.destroy$)).subscribe({
			next: () => {
				this.clearNumberPage.emit();
			},
		});
		this.submitSearchEvent(true);
		this.checkScreenSize();
		this.mapHeaderService.isOpen.pipe(takeUntil(this.destroy$)).subscribe({
			next: status => this.isOpenMap.set(status)
		});
		this.mapHeaderService.radiusSubject.pipe(takeUntil(this.destroy$)).subscribe({
			next: radius => {
				this.radius = radius;
			}
		});

	}

	public clearOldDate(): void {
		this.selectedEndDate$.set(null);
	}

	public checkScreenSize(): void {
		this.isTouchUI = window.innerWidth < 720;
	}

	public setEvent(data: Events.EventResponseData): void {
		const { events, labels, total } = data;

		this.eventsArr.emit({
			data: events,
			labels,
			total,
			error: false,
		});
	}

	public loadMoreEvents(pageNumber?: number): void {
		super.getEvents(this.typeSearch, this.selectedStartDate$()!,
			this.time$(), pageNumber, undefined, this.typesSearchTags);
	}

	public submitSearchEvent(isInitSearch?: boolean): void {
		this.clearNumberPage.emit();
		if (isInitSearch) {
			super.getEvents('recommendation', this.selectedStartDate$()!, this.time$());
			return;
		}
		this.wsService.isLoading.next(true);

		this.submitNewSearch.emit();

		if(this.typesSearchTags.length > 0) {
			super.getEvents('default', this.selectedStartDate$()!, this.time$(),
				undefined, undefined, this.typesSearchTags);
			return;
		}

		super.getEvents('default', this.selectedStartDate$()!, this.time$());

	}

	public selectTags(event: MultiSelectChangeEvent): void {
		this.typesSearchTags = event.value.map((item: any) => item.id);
		this.typesSearchNames = event.value.map((item: any) => item.name);
	}

	public onAddressChange(value: string): void {
		if (!value || value.trim() === '') {
			this.selectName = [];
		}
	}

	public showDialogSettings(): void {
		this.openSettings = true;
	}

	public visibleMap(): void {
		this.isOpenMap.set(!this.isOpenMap());
	}

	public searchByAddress(event: AutoCompleteCompleteEvent): void {
		this.address$.next(event.query);
	}

	public selectAdress(event: any): void {
		this.coordinateService.searchCoordinateByAddress(event.value);
	}

	private setTimeMinutes(): void {
		effect(() => {
			if (!this.selectedEndDate$()) {
				this.time$.set(60 * 24);
				return;
			}
			const startOfDay = this.selectedStartDate$()!.startOf('day');
			const endOfDay = this.selectedEndDate$()!.endOf('day');
			const duration = moment.duration(endOfDay.diff(startOfDay));
			const minutes = Math.round(duration.asMinutes());
			this.time$.set(minutes);
		}, { injector: this.injector, allowSignalWrites: true });
	}

	public get valuePlaceHolder(): string {
		this.cdr.detectChanges();
		return this.geoService.currentAdress.value ? this.geoService.currentAdress.value : 'Адрес';
	  }

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
