import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
	name: 'formatDateSchedule',
})
export class FormatDateSchedulePipe implements PipeTransform {
	public transform(dateString: string): string {
		const date = moment(dateString);
		const today = moment();
		const tomorrow = moment().add(1, 'days');

		if (date.isSame(today, 'day')) {
			return date.format('Сегодня в HH-mm');
		}

		if (date.isSame(tomorrow, 'day')) {
			return date.format('Завтра в HH-mm');
		}
		return date.format('DD.MM в HH-mm');
	}
}
