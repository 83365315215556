import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getLink',
})
export class GetLinkPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) return value;

        let result = value.replace(/\n/g, '<br>');

        const urlRegex = /(https:\/\/[^\s]+)/g;
        return result.replace(urlRegex, (match) => {
            return `<a href="${match}" target="_blank">${match}</a>`;
        });
    }
}
