import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TypeImageService } from '../../core/services/type-image.service';

@Pipe({
	name: 'checkUploadImage',
})
export class CheckUploadImagePipe implements PipeTransform {
	constructor(private typeImageService: TypeImageService) {}

	transform(imageUrl: string | undefined | null): Observable<string> {
		if (!imageUrl) {
			return of(this.typeImageService.typesDefault[0].url);
		}

		return new Observable<string>(observer => {
			const img = new Image();
			img.onload = () => {
				observer.next(imageUrl);
				observer.complete();
			};
			img.onerror = () => {
				observer.error(new Error(`Failed to load image: ${imageUrl}`));
			};
			img.src = imageUrl;
		}).pipe(
			catchError(() => of(this.typeImageService.typesDefault[0].url))
		);
	}
}
