import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { Events } from 'src/app/core/interfaces/events';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CardModule } from 'primeng/card';
import { TypeImageService } from '../../../core/services/type-image.service';
import { PipeModule } from '../../pipes/pipes.module';

@Component({
	selector: 'app-events-item',
	standalone: true,
	imports: [CommonModule, CardModule, ButtonModule, PipeModule],
	templateUrl: './events-item.component.html',
	styleUrl: './events-item.style.scss',
	host: { class: 'event' },
	providers: [TypeImageService],
})
export class EventsItemComponent {
	@Input({ required: true }) item!: Events.ItemWsDefault;

	@Output() onAction = new EventEmitter();

	public typeImageService: TypeImageService;

	constructor(typeImageService: TypeImageService) {
		this.typeImageService = typeImageService;
	}

	public openDetail(): void {
		this.onAction.emit();
	}

	public openDetailMobile(): void {
		if (window.innerWidth <= 720) {
			this.openDetail();
		}
	}

	public navigateToDetail(id: number): void {
		window.open(`profile/${id}`, '_blank');
	}
}
