import { NgModule } from '@angular/core';
import { EventIcon } from './event-icon.pipe';
import { Timer } from './time';
import { TimeOfDay } from './time-of-day.pipe';
import { CoordinatesPipe } from './coordinates.pipe';
import { FormattedDatePipe } from './formatted-date.pipe';
import { GetLinkPipe } from './get-link.pipe';
import { DistanceCurrentPositionPipe } from './distance-current-position.pipe';
import { TransformTagsPipe } from './transform-tags.pipe';
import { GetAdressPipe } from './get-adress.pipe';
import { FormatDateSchedulePipe } from './format-date-shedule.pipe';
import { CheckUploadImagePipe } from './check-upload-image.pipe';

@NgModule({
	declarations: [
		Timer,
		EventIcon,
		TimeOfDay,
		CoordinatesPipe,
		FormattedDatePipe,
		GetLinkPipe,
		DistanceCurrentPositionPipe,
		TransformTagsPipe,
		GetAdressPipe,
		FormatDateSchedulePipe,
		CheckUploadImagePipe
	],
	exports: [
		Timer,
		EventIcon,
		TimeOfDay,
		CoordinatesPipe,
		FormattedDatePipe,
		GetLinkPipe,
		DistanceCurrentPositionPipe,
		TransformTagsPipe,
		GetAdressPipe,
		FormatDateSchedulePipe,
		CheckUploadImagePipe
	],
})
export class PipeModule {}
